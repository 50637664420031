::-webkit-calendar-picker-indicator {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path style="cursor: pointer; fill: aqua" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

* {
  font-family: "Poppins", sans-serif !important;
  font-weight: 400;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.MuiTypography-h3 {
  font-weight: 600;
}

/* body .MuiTypography-h5 {
  color: #ffffff;
} */

body .MuiFormControl-marginDense {
  margin-bottom: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.MuiDrawer-docked .MuiSvgIcon-root {
  color: #ffffff;
}

.MuiTypography-colorTextSecondary {
  font-weight: 500 !important;
}

.MuiList-root a {
  text-decoration: none;
}

.MuiButton-textPrimary {
  color: #1c2023;
}

.MuiTabs-root {
  color: #ffffff;
  padding-bottom: 15px;
}

body .MuiTab-fullWidth,
body .form-tab {
  color: #202020;
  border-bottom: 1px solid #202020;
}

body .form-tab {
  flex: 1;
}

body .mybg .MuiTabs-flexContainer {
  border-bottom: 1px solid #202020;
}

.MuiButton-textPrimary {
  color: #ffffff !important;
  font-weight: 500 !important;
}

.MuiButton-textSizeSmall {
  padding: 7px 14px !important;
  font-size: 0.8125rem !important;
}

.MuiTableSortLabel-root {
  font-weight: 700;
  white-space: nowrap;
  padding: 10px 0 !important;
}

body .RaListToolbar-toolbar-52,
body .RaListToolbar-toolbar-68 {
  align-items: center;
  padding-right: 0;
}

body .MuiFilledInput-input,
body .MuiInputLabel-formControl {
  color: #202020 !important;
  padding-right: 25px;
}

body .MuiToolbar-root .MuiFilledInput-input {
  background: #ffffff;
}

body .MuiFilledInput-underline:before,
body .MuiFilledInput-underline:hover:before {
  border-bottom: 2px solid  rgba(28, 32, 35, .2);;
}

.search {
  background-image: url(assets/images/search.png);
  background-repeat: no-repeat;
  background-position: center right;
}

body .MuiFormControl-root ::placeholder {
  color: white !important;
}

body .RaFilterButton-root-67,
body .RaFilterButton-root-82,
body .RaFilterButton-root-71 {
  display: inline-block;
  margin-right: 12px;
}

body .MuiIconButton-root {
  color: #5A35B6;
}

body .MuiTableCell-sizeSmall {
  padding: 6px 10px 6px 10px;
}

body .MuiTableRow-root {
  height: 65px;
}

body .MuiTableCell-root {
  border: 0;
  font-size: 16px;
}

body .MuiTableSortLabel-root:hover {
  color: #09102A;
}

body .MuiDivider-root {
  height: 0;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.next-page.MuiButton-textPrimary.MuiButton-textSizeSmall.MuiButton-sizeSmall,
body .MuiButton-root {
  margin-left: 10px;
  background: #673de6;
}

.MuiButton-root:hover {
  background-color: #673de6 !important
}

body .MuiTableCell-sizeSmall:last-child .RaButton-smallIcon-11 {
  font-size: 14px;
  width: 75px;
}

/* body .simple-form,
body .RaEdit-card-64,
body .mybg .MuiToolbar-root,
body .RaToolbar-toolbar-117,
body .RaToolbar-toolbar-121,
body .RaToolbar-toolbar-131,
body .RaToolbar-toolbar-111,
body .RaToolbar-toolbar-89,
body .RaToolbar-toolbar-91,
body .RaToolbar-toolbar-92,
body .RaToolbar-toolbar-94,
body .RaToolbar-toolbar-93,
body .RaToolbar-toolbar-90,
body .MuiPaper-root.MuiCard-root.RaEdit-card-62.MuiPaper-elevation1.MuiPaper-rounded {
  background: #1C2023;
} */

body .mybg .MuiFilledInput-input,
body .mybg .MuiInputLabel-formControl {
  color: #ffffff;
}

body .mybg .MuiFilledInput-underline:before,
body .mybg .MuiFilledInput-underline:hover:before {
  border-bottom: 1px solid #A6A6A7;
}

body .mybg .MuiFilledInput-underline input {
  padding-left: 0;
}

body .mybg .MuiButton-root {
  margin-left: 0;
}

body .MuiInputLabel-filled.MuiInputLabel-marginDense {
  transform: translate(0, 17px) scale(1);
}

body .MuiInputLabel-filled.MuiInputLabel-shrink.MuiInputLabel-marginDense {
  transform: translate(0, 7px) scale(0.75);
}

body .ra-rich-text-input .ql-toolbar.ql-snow {
  padding: 10px;
}

body .ra-rich-text-input .ql-toolbar.ql-snow .ql-picker-label {
  color: #fff;
  opacity: 0.7;
}

body .ra-rich-text-input .ql-toolbar.ql-snow+.ql-container.ql-snow {
  height: 200px;
  color: #fff;
}

body .mb-5 {
  margin-bottom: 30px;
}

body .ra-rich-text-input .ql-toolbar.ql-snow button .ql-stroke {
  stroke: #fff;
  opacity: 0.7;
}

body .RaCreate-card-117 {
  border-radius: 0px;
}

body .ql-container.ql-snow {
  border-bottom: 0px solid transparent !important;
}

body .RaListToolbar-toolbar-56 {
  justify-content: flex-end;
}

body .MuiPaper-root.MuiCard-root.RaList-content-52.MuiPaper-elevation1.MuiPaper-rounded {
  max-width: 1060px;
  overflow: auto !important;
}

body .mycards,
body .stripecards {
  margin-bottom: 25px;
  margin-top: 25px;
}

body .RaEmpty-message-114,
body .RaNotFound-message-119 {
  color: #fff;
  font-weight: 400;
  opacity: 1;
}

body .mycards .MuiPaper-root {
  border-radius: 12px;
  border: 0;
  min-height: 100%;
}

body .mycards .MuiPaper-root h3 {
  font-size: 1.8rem;
  font-weight: 500;
  color: white;
}

body .mycards .MuiPaper-root span {
  font-weight: 400;
  color: white;
}

body .stripecards .MuiPaper-root {
  border: 0;
  min-height: 100%;
  border-radius: 20px;
  box-shadow: 0px 37px 54px -30px rgb(0 0 0 / 75%);
  background-image: linear-gradient(to right bottom, #181c1f, #1e404c, #0b6974, #009694, #1dc3a7);
}

body .stripecards .MuiPaper-root h3 {
  font-size: 1.8rem;
  font-weight: 500;
  color: white;
}

body .text-danger {
  color: #d50000 !important;
}

/* body .appmenu  .MuiIconButton-root {
  color: #ffffff;
} */

body .appmenu {
  background: #ffffff;
  box-shadow: none;
}

body .btngreen {
  min-width: 125px;
  border: 1px solid #1DC3A7;
  color: #1DC3A7 !important;
  font-size: 16px;
  background: transparent !important;
}

body .btngreen:hover {
  background: #1DC3A7 !important;
  border: 1px solid #1DC3A7;
  color: #fff !important;
}

body .btnred {
  min-width: 125px;
  border: 1px solid #fa6a6a;
  color: #fa6a6a !important;
  font-size: 16px;
  background: transparent !important;
  text-transform: uppercase;
}

body .btnred:hover {
  background: #fa6a6a !important;
  border: 1px solid #fa6a6a;
  color: #fff !important;
}

.MuiToolbar-root.MuiToolbar-dense.RaAppBar-toolbar-15:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 240px;
  height: 100%;
  z-index: -1;
}

body .MuiTab-textColorInherit.Mui-selected {
  background-color: rgba(90, 53, 182, .3);
}

body .MuiTab-textColorInherit span {
  color: #202020;
}

body .MuiTab-textColorInherit.Mui-selected span {
  color: #202020;
  font-weight: 500;
}

body .table-responsive .MuiTableCell-sizeSmall {
  padding: 6px 24px 6px 16px;
}

.d-flex {
  display: flex;
  align-items: center;
}

.justify-space-between {
  justify-content: space-between;
}

.mt-40 {
  margin-top: 40px;
}

.mb-40 {
  margin-bottom: 40px;
}

.font-weight-med {
  font-weight: 600;
}

.max-200 {
  max-width: 200px;
  margin-left: auto;
  margin-right: auto;
}

body .editinputs .MuiFilledInput-input {
  padding-left: 0;
  padding-right: 0;
  padding-top: 30px;
  padding-bottom: 8px;
  color: #fff;
}

body .editinputs .MuiFilledInput-underline:before,
body .MuiFilledInput-underline:hover:before {
  border-bottom: 1px solid #09102A;
}

body .MuiFormLabel-root.Mui-focused {
  color: #ffffff;
}

body .myforms {
  width: 745px;
  margin-left: auto;
  margin-right: auto;
}

video {
  border: 3px solid #252a2d;
}

.previews {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.previews div {
  margin-right: 25px;
  position: relative;
  margin-bottom: 25px;
}

.previews div button {
  position: absolute;
  right: -10px;
  padding: 0;
  top: -10px;
}

.makeStyles-root-61>* {
  margin: 0 !important;
}

.RaSimpleFormIterator-line-675 {
  border-bottom: 0 !important;
}

.halfclosemenu {
  width: 55px;
}

.halfclosemenu .MuiListItemText-root {
  display: none;
}

.halfclosemenu .MuiList-root {
  width: 40px;
}

.halfclosemenu svg.MuiSvgIcon-root {
  display: none;
}

::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  border: 1px solid #d5d5d5;
}

::-webkit-scrollbar-track {
  border-radius: 0;
  background: #eeeeee;
}

::-webkit-scrollbar-thumb {
  border-radius: 0;
  background: #b0b0b0;
}

/* input#date {
  color: #ffffff;
} */

#react-admin-title .MuiTypography-root.MuiTypography-gutterBottom {
  color: #ffffff;
}

#react-admin-title span,
#react-admin-title h6 {
  color: #202020;
  font-size: 2.2rem !important;
  font-weight: 400;
  line-height: 1.167;
  letter-spacing: 0em;
  text-transform: capitalize;
}

.detail-input input {
  width: 350px;
}

.MuiTableSortLabel-root {
  white-space: normal !important;
}

.MuiTypography-body2 {
  font-size: 15px !important;
}

.makeStyles-root-73 .MuiSelect-root,
.makeStyles-root-73 .MuiInputBase-input {
  margin-top: 0 !important;
}

.MuiFormHelperText-root.Mui-error {
  color: red !important;
  font-size: 14px;
  margin-left: 0;
}

.MuiSelect-icon {
  color: #5A35B6 !important;
}

.MuiTab-root {
  max-width: 50% !important;
}

.overview-page {
  padding: 0 !important;
}

.overview-page .mycards {
  margin-bottom: 0;
}

.RaSidebar-drawerPaper-40 {
  background-color: #ffffff !important;
}

.MuiTableCell-head span {
  font-weight: 500!important;
}

.MuiAvatar-colorDefault {
  background-color: #673de6 !important;
}

.sidebar-icons {
  font-size: 28px;
  color: #202020;
}

@media (min-width: 1280px) {
  body .MuiContainer-maxWidthLg {
    max-width: 100%;
  }

  .MuiPaper-root.MuiCard-root.RaList-content-48.MuiPaper-elevation1.MuiPaper-rounded {
    width: 1000px !important;
    overflow: auto !important;
  }
}

@media (min-width: 0px) {
  .RaLayout-content-6 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media (min-width: 600px) {
  body .mybg .MuiToolbar-gutters {
    padding-left: 15px;
    padding-right: 24px;
  }
}

@media(max-width:1280px) {
  body .btngreen {
    min-width: 105px;
  }

  body .btnred {
    min-width: 105px;
  }

  .RaLayout-content-122 {
    padding: 8px;
  }

  .MuiContainer-root {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .RaEdit-main-64 .MuiTab-root {
    min-width: 149px !important;
  }

  body .myforms {
    width: 620px;
  }

  .makeStyles-title-1 {
    padding: 0 0 0 0 !important;
  }

  body .MuiTableRow-root {
    height: 50px;
  }
}

.ra-confirm{
  color: black !important;
}